dmx.Component('flow', {

    initialData: {
        $param: null,
        data: null,
        running: false,
        lastError: null
    },

    attributes: {
        src: {
            type: String,
            default: null
        },

        preload: {
            type: Boolean,
            default: false
        },

        autorun: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        run: function(param) {
            this.run(param);
        }
    },

    events: {
        start: Event,
        done: Event,
        error: Event
    },

    render: function(node) {
        if (this.props.src) {
            
        } else {
            try {
                this.flow = (window.Hjson ? Hjson : JSON).parse(node.textContent);
                if (this.props.autorun) {
                    this.run();
                }
            } catch (e) {
                console.error(e);
                if (dmx.debug) {
                    console.debug(node.textContent);
                }
            }
        }
    },

    update: function(props) {
        // nothing dynamic
    },

    run: function(param) {
        var self = this;

        if (this.flow) {
            if (this.data.running) {
                console.info('Flow ' + this.name + ' is already running.');
                return;
            }

            this.set('running', true);
            this.set('log', {});
            this.set('$param', param);
            this.set('lastError', null);

            this.dispatchEvent('start');

            dmx.Flow.run(this.flow, this).then(function(data) {
                self.set('running', false);
                self.set('data', data);
                self.dispatchEvent('done');
                if (dmx.debug) {
                    console.debug('done', data);
                }
            }).catch(function(err) {
                self.set('running', false);
                self.set('lastError', err && err.message);
                self.dispatchEvent('error');
                console.error(err);
            });
        }
    }

});
