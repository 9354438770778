dmx.Actions({
    /**
     * Add new columns to the collection
     * @param {Object} options
     * @param {Object[]} options.collection - The collection
     * @param {Object.<string,*>} options.add - Object with column name as key and the value
     * @param {boolean} [options.overwrite=false] - Overwrite existing columns
     * @returns {Object[]} - New collection
     */
    'collection.addColumns': function(options) {
        var collection = this.parse(options.collection);
        var add = this.parse(options.add);
        var overwrite = !!this.parse(options.overwrite);

        if (!collection.length) return [];
        
        var output = [];
        
        for (var i = 0, l = collection.length; i < l; i++) {
            var row = dmx.clone(collection[i]);

            for (var column in add) {
                if (overwrite || !row[column]) {
                    row[column] = add[column];
                }
            }

            output.push(row);
        }

        return output;
    },

    /**
     * Remove entire specified columns from the collection
     * @param {Object} options
     * @param {Object[]} options.collection - The collection
     * @param {string[]} options.columns - The columns
     * @param {boolean} [options.keep=false] - Keep or remove the columns
     * @returns {Object[]} - New collection
     */
    'collection.filterColumns': function(options) {
        var collection = this.parse(options.collection);
        var columns = this.parse(options.columns);
        var keep = !!this.parse(options.keep);

        if (!collection.length) return [];

        var output = [];

        for (var i = 0, l = collection.length; i < l; i++) {
            var row = collection[i];
            var newRow = {};

            for (var column in row) {
                if (columns.includes(column)) {
                    if (keep) {
                        newRow[column] = dmx.clone(row[column]);
                    }
                } else if (!keep) {
                    newRow[column] = dmx.clone(row[column]);
                }
            }

            output.push(newRow);
        }

        return output;
    },

    /**
     * Rename columns in the collection
     * @param {Object} options
     * @param {Object[]} options.collection - The collection
     * @param {Object.<string,string>} options.rename - Object with old name as key and new name as value
     * @returns {Object[]} - New collection
     */
    'collection.renameColumns': function(options) {
        var collection = this.parse(options.collection);
        var rename = this.parse(options.rename);

        if (!collection.length) return [];
 
        var output = [];

        for (var i = 0, l = collection.length; i < l; i++) {
            var row = collection[i];
            var newRow = {};

            for (var column in row) {
                newRow[rename[column] || column] = dmx.clone(row[column]);
            }

            output.push(newRow);
        }

        return output;
    },

    /**
     * Fills empty rows with the row above's value
     * @param {Object} options
     * @param {Object[]} options.collection - The collection
     * @param {string[]} options.columns - The columns to fill
     * @returns {Object[]} - New collection
     */
    'collection.fillDown': function(options) {
        var collection = this.parse(options.collection);
        var columns = this.parse(options.columns);

        if (!collection.length) return [];

        var output = [];
        var toFill = {};

        for (var i = 0, l = columns.length; i < l; i++) {
            toFill[columns[i]] = collection[0][colums[i]];
        }

        for (var i = 0, l = collection.length; i < l; i++) {
            var row = dmx.clone(collection[i]);

            for (var column in toFill) {
                if (!row[column]) {
                    row[column] = toFill[column];
                }
            }

            output.push(row);
        }

        return output;
    },

    /**
     * Add new rows to the collection
     * @param {Object} options
     * @param {Object[]} options.collection - The collection
     * @param {Object[]} options.rows - The rows to add
     * @returns {Object[]} - New collection
     */
    'collection.addRows': function(options) {
        var collection = this.parse(options.collection);
        var rows = this.parse(options.rows);

        return dmx.clone(collection).concat(rows);
    },

    /**
     * Summarizes the data by one or more columns
     * @param {Object} options
     * @returns {Object} - new Collection
     */
    'collection.groupBy': function(options) {

    },

    /**
     * Join two collections (Left join)
     * @param {Object} options
     * @param {Object[]} options.collection1 - Left collection
     * @param {Object[]} options.collection2 - Right collection
     * @param {Object.<string,string>} options.matches - Columns to match
     * @param {boolean} [options.matchAll=false] - Match columns using AND instead of OR
     * @returns {Object} - new Collection
     */
    'colection.join': function(options) {
        var collection1 = this.parse(options.collection1);
        var collection2 = this.parse(options.collection2);
        var matches = this.parse(options.matches);
        var matchAll = !!this.parse(options.matchAll);

        var output = [];

        for (var i = 0, l = collection1.length; i < l; i++) {
            var row = dmx.clone(collection1[i]);

            for (var j = 0, l2 = collection2.length; j < l2; j++) {
                var row2 = collection2[i];
                var hasMatch = false;

                for (var match in matches) {
                    if (row[match] == row2[matches[match]]) {
                        hasMatch = true;
                        if (!matchAll) break;
                    } else if (matchAll) {
                        hasMatch = false;
                        break;
                    }
                }

                if (hasMatch) {
                    for (var column in row2) {
                        // TODO duplicate row from collection1 when multiple matches exist in collection2
                        // TODO check for duplicate column names
                        row[column] = dmx.clone(row2[column]);
                    }
                    break;
                }
            }

            output.push(row);
        }

        return output;
    },

});